var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "event-board", staticStyle: { "text-align": "center" } },
        [
          _c("div", { staticClass: "medical-detail-202407" }, [
            _c("div", { staticClass: "status-wrap" }, [
              _c("div", { staticClass: "number-acquired number-box" }, [
                _vm._v(
                  " " + _vm._s(_vm.isLogOutMember ? 0 : _vm.takeKeyCount) + " "
                ),
              ]),
              _c("div", { staticClass: "number-openAble number-box" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.isLogOutMember
                        ? 0
                        : parseInt(_vm.takeKeyCount / _vm.sectionStepCount) -
                            _vm.openBoxCount
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "number-open number-box" }, [
                _vm._v(
                  " " + _vm._s(_vm.isLogOutMember ? 0 : _vm.openBoxCount) + " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "btn-open-event-06" }, [
              _c("a", { attrs: { title: "선물 박스" } }, [
                _c("img", {
                  style: _vm.getImageStyle(_vm.openBtnState),
                  attrs: { src: _vm.getImagePath(_vm.openBtnState) },
                  on: {
                    click: function ($event) {
                      return _vm.getImageClick(_vm.openBtnState)
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "btn-go-banner" }, [
              _c(
                "a",
                {
                  attrs: { title: "메디컬 트렌드 보고 스티커 적립하기" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({
                        name: "app-medical-trend-main",
                        query: { entry: 122 },
                      })
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/events/event240611/btn-save-detail.png"),
                      alt: "메디컬 트렌드 보고 스티커 적립하기",
                    },
                  }),
                ]
              ),
            ]),
            _c("img", {
              attrs: {
                src: require("@/assets/images/events/event240611/detail-bg-2407.png"),
                alt: "선생님의 의견이 소중한 정보가 되는 닥터보이스",
              },
            }),
          ]),
        ]
      ),
      _c(_vm.popupComponent, {
        ref: "preProcessPopup",
        tag: "component",
        attrs: { "event-id": _vm.eventId },
        on: { close: _vm.closePreProcessPopup },
      }),
      _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
      _c("confirm-dialog", { attrs: { options: _vm.confirmProps } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }